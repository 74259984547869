@import '@/styles/variables.scss';





























































































































































































































.my-bav {
  &__panels {
    .v-expansion-panel-header--active {
      background-color: #f3f3f3 !important;
      border-bottom: none !important;
      &:before {
        background-color: #f3f3f3 !important;
      }
    }
  }
}
