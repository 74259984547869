@import '@/styles/variables.scss';















































































































































































































































































































































































































































.v-stepper__label {
  color: #2830ce !important;
}
.v-stepper__header {
  &[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
  }
}

.v-stepper__wrapper {
  padding: 10px;
}
.v-stepper__step {
  cursor: pointer;
  &[disabled] {
    pointer-events: none;
  }
}

.cursor-disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.v-stepper__header {
  .v-divider {
    border-color: rgba(40, 48, 206, 0.25) !important;
    padding: 1px;
    border-width: 2px !important;
  }
}

.request-offer {
  &__btn {
    background: #2830ce;
    border-radius: 3px;
    min-width: 300px !important;
    &--white {
      background: #ffffff;
      border: 1px solid #2830ce;
      border-radius: 3px;
      min-width: 300px !important;
      color: #2830ce !important;
    }
    &--mobile {
      max-width: 100% !important;
      min-width: 100% !important;
    }
  }
}

.offer-type {
  display: flex;
  align-items: center;
  /*height: 60px;*/
  width: 100%;
  background: #2830ce;
  border: 1px solid #2830ce;
  border-radius: 3px;
  margin-top: 25px;
  padding: 10px;

  .v-input--radio-group {
    margin: 0 !important;
  }

  div[role='radiogroup'] {
    label {
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      color: #ffffff;
    }

    .v-icon {
      color: white !important;
    }
  }
}

@media only screen and (min-width: 959px) {
  .offer-type {
    height: 38px;
    justify-content: center;
    .v-input--radio-group {
      margin: 0 !important;
    }

    .v-radio {
      margin: 0 !important;
    }

    .v-input--radio-group--column .v-radio:not(:last-child):not(:only-child) {
      margin: 0 !important;
      margin-right: 80px !important;
    }

    div[role='radiogroup'] {
      flex-direction: row;
      color: white;
      margin: 0;
      margin-bottom: 5px;

      label {
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;

        color: #ffffff;
      }

      .v-icon {
        color: white !important;
      }
    }
  }
}
