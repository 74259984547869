@import '@/styles/variables.scss';





























































































.large-tile {
  // position: relative;
  border-radius: 3px;
  margin-bottom: 3px;
  // max-height: 415px;
  // min-height: 415px;
  padding: 12px;
  padding-bottom: 16px;
  box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%);
  flex: 1 0 auto;
  .v-card__title {
    min-height: 4px;
    flex: 0 0 auto;
    align-items: flex-start;
  }
  &-img {
    background: rgba(128, 178, 255, 0.5);
    border-radius: 3px;
    width: 100%;
    height: 190px;
    object-fit: contain;
  }
  &-title {
    font-weight: 700;
    font-size: 19px;
    line-height: 23px;
    color: #2830ce;
    padding: 0 !important;
    margin-top: 32px;
    margin-bottom: 8px;
  }
  &-text {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    padding: 0 !important;
  }
  &-btn {
    position: absolute;
    right: 12px;
    bottom: 16px;
    border-radius: 3px;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #06fccd !important;
  }
}

@media screen and (max-width: 960px) {
  .large-tile {
    margin-left: 5px !important;
    margin-right: 5px !important;

    .v-card__cta {
      button {
        width: 100%;
      }
    }
    &-img {
      height: 160px;
    }
    &-title {
      margin-top: 13px !important;
      margin-bottom: 6px;
      font-size: 14px;
    }
    &-text {
      font-size: 12px;
      flex: 1 0 auto;
    }
    &-btn {
      width: calc(100% - 24px);
      &--offer {
        width: calc(100% - 36px);
      }
    }
  }
}
