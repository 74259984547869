@import '@/styles/variables.scss';








































































































































































































































































































































.v-data-table--custom {
  th {
    text-align: left;
    background-color: white !important;
    border-color: white !important;
  }
  tr td {
    border-color: white !important;
  }
}

.check-data-heading {
  font-weight: 600;
  font-size: 16px !important;
  line-height: 19px;
  text-align: left;
  color: #2830ce !important;
  margin-bottom: 23px;
  white-space: nowrap;
  padding-left: 16px;
}
