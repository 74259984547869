@import '@/styles/variables.scss';


















































































































































































.v-list--custom {
  display: grid;
  grid-template-columns: max-content max-content;
  grid-template-rows: 1fr 1fr;
  gap: 0px 0px;
  grid-template-areas:
    '. .'
    '. .';
  justify-content: center;
  .v-select {
    max-width: 200px;
  }
  .select-custom {
    top: -8px;
  }
}
