@import '@/styles/variables.scss';













































































































































































































































































































































































































































































































































































































.badge-custom {
  width: 100%;
}
.label-custom {
  position: absolute;
  top: -34px;
  left: 10px;
  width: auto;
  min-width: 75px;
  &--2 {
    min-width: 120px;
  }
  .info {
    margin-left: 15px;
  }
}

@media screen and (max-width: 960px) {
  .v-badge__badge {
    display: none !important;
  }
}
