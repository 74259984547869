@import '@/styles/variables.scss';























































































































































.my-bav {
  padding-top: 33px !important;
  &__status {
    position: fixed;
    width: 100%;
    height: 42px;
    left: 0px;
    top: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    background: #f9b60d;
    z-index: 5000;
  }
  .v-tabs-bar.v-tabs-bar--is-mobile:not(.v-tabs-bar--show-arrows)
    > .v-slide-group__wrapper
    > .v-tabs-bar__content
    > .v-tab:first-of-type {
    margin-left: unset !important;
  }

  .v-tabs-bar {
    height: 61px !important;
  }

  .v-tabs-items {
    background-color: transparent !important;
  }
  .v-tab {
    font-size: 14px !important;
    text-transform: none !important;
    padding-top: 13px;
    padding-bottom: 8px;
    letter-spacing: normal !important;
  }
  &-next {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    width: 100%;
  }
  &__tab {
    &-text {
      color: #898989;
      margin-top: 9px;
    }
    &--active {
      background-color: rgba(108, 189, 255, 0.5);
      font-weight: bold;
      .my-bav__tab-text {
        color: #2830ce;
        font-weight: bold;
      }
    }
  }
}
.v-tabs-overlay {
  position: relative;
}
.v-tabs-overlay::after {
  background-color: white;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  content: '';
  z-index: 4;
  transition: opacity 250ms ease-in-out;
  opacity: 0;
  pointer-events: none; /* Pass events through the "fade" layer */
}
.scrolling::after {
  opacity: 1;
}

@media screen and (max-width: 960px) {
  .my-bav {
    .v-stepper:not(.v-stepper--vertical) .v-stepper__label {
      display: flex !important;
      word-break: break-word;
      white-space: pre-wrap;
    }
    .v-tabs-bar {
      height: 50px !important;
      box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.05);
      border-radius: 5px;
    }
    .v-tab {
      max-width: 50px;
      line-height: 12px !important;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 !important;
    }
    &__tab-text {
      max-width: 95px;
      font-size: 10px !important;
      margin: 0 !important;
    }
    &-tabs {
      padding: 0;
    }
  }
}

@media only screen and (max-width: 959px) {
  .v-divider {
    transform: scale(0.4);
  }
  .v-stepper__step {
    padding: 24px 19px;
  }
  .v-stepper--alt-labels .v-stepper__step {
    flex-basis: 110px;
  }
  .v-stepper:not(.v-stepper--vertical) .v-stepper__label {
    display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
    display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */
    display: -ms-flexbox; /* TWEENER - IE 10 */
    display: -webkit-flex; /* NEW - Chrome */
    display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */
    font-weight: 400;
    font-size: 12px !important;
    line-height: 15px;
    justify-content: center;
  }
  @media only screen and (max-width: 959px) {
    .v-stepper:not(.v-stepper--vertical) .v-stepper__step__step {
      margin-right: 5px;
    }
  }
}
